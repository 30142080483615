// Auto-generated RPC interface from /opt/whdata/installedmodules/nubshop.20240607T124514.854Z/web/shared/js/nubshop.rpc.json
var RPCClient = require("@mod-system/js/wh/rpc").default;
var request = exports.rpcclient = new RPCClient("nubshop:rpc");
exports.rpcResolve = function(promise, result) { request._handleLegacyRPCResolve(promise, result) };
exports.invoke = function() { return request.invoke.apply(request,Array.prototype.slice.call(arguments)); }

Object.defineProperty(module.exports, "HTTP_ERROR", { get: function() { return JSONRPC.HTTP_ERROR; }});
Object.defineProperty(module.exports, "JSON_ERROR", { get: function() { return JSONRPC.JSON_ERROR; }});
Object.defineProperty(module.exports, "PROTOCOL_ERROR", { get: function() { return JSONRPC.PROTOCOL_ERROR; }});
Object.defineProperty(module.exports, "RPC_ERROR", { get: function() { return JSONRPC.RPC_ERROR; }});
Object.defineProperty(module.exports, "OFFLINE_ERROR", { get: function() { return JSONRPC.OFFLINE_ERROR; }});
Object.defineProperty(module.exports, "TIMEOUT_ERROR", { get: function() { return JSONRPC.TIMEOUT_ERROR; }});
Object.defineProperty(module.exports, "SERVER_ERROR", { get: function() { return JSONRPC.SERVER_ERROR; }});

// Adding dependency: '/opt/whdata/installedmodules/nubshop.20240607T124514.854Z/lib/rpc.whlib'

exports.toggleWishListItem = exports.ToggleWishListItem = /*RECORD*/function(/*INTEGER*/ fileid, /*INTEGER*/ productid)
{
return request.invoke.apply(request,["ToggleWishListItem"].concat(Array.prototype.slice.call(arguments)));
}

exports.verifyCouponCode = exports.VerifyCouponCode = /*RECORD*/function(/*INTEGER*/ fileid, /*STRING*/ couponcode)
{
return request.invoke.apply(request,["VerifyCouponCode"].concat(Array.prototype.slice.call(arguments)));
}

exports.getDeferredProductsHTML = exports.GetDeferredProductsHTML = /*RECORD*/function(/*INTEGER*/ fileid, /*INTEGER ARRAY*/ prodids)
{
return request.invoke.apply(request,["GetDeferredProductsHTML"].concat(Array.prototype.slice.call(arguments)));
}
